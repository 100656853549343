import React, { useEffect } from 'react';

const StripePaymentCard = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://js.stripe.com/v3/buy-button.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <stripe-buy-button
            buy-button-id="buy_btn_1PqgESCLopzQUiIKv9ErjMrG"
            publishable-key="pk_live_51PUMvFCLopzQUiIKUQwS8eS2Ha8tUcIXLMLd7ThxpiVUcO9A49moiM7e840LUWkFdx7pYRY7ghI73jhgDCiaE8Ye00WFZTAcKM"
        >
        </stripe-buy-button>
    );
}

export default StripePaymentCard;
