import React from "react";
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";

// Sections
import { 
    Hero,
    DataTransformed, 
    TopKeyfeatures, 
    Media,
    BlackRounded,
    FAQ,
    DiveInto 
} from "../../sections/TeleAnalytix";

// Images
import { 
    InterfaceInCircle, 
    InteractiveChatbotInterfaceMobile, 
    InteractiveChatbotInterfaceDesktop,
    CodeInCircle, 
    ApiIntegrationLogo, 
    ApiIntegrationVideo,
    AIPoweredInsightsVideo,
    AIPoweredInsightsPoster,
    ApiIntegrationPoster,
    
    StarsInCircle
} from "../../helpers/images";

const TeleAnalytixAI = () => {
    return (
        <div className="app-layout app-teleanalytixai">
            <Header/>
            
            <main className="app-main">                
                <Hero/>
                <DataTransformed/>   
                <TopKeyfeatures/>             
                
                {/* Interactive Chatbot Interface */}
                <Media 
                    title={'Interactive Chatbot Interface'} 
                    icon={InterfaceInCircle}
                    media={{
                        desktop: InteractiveChatbotInterfaceDesktop,
                        mobile: InteractiveChatbotInterfaceMobile 
                    }} />
                
                {/* TelecomsXChange API Integration */}
                <Media 
                    title={ApiIntegrationLogo} 
                    icon={CodeInCircle} 
                    media={{
                        desktop: ApiIntegrationVideo,
                        poster: ApiIntegrationPoster                        
                    }}/>

                {/* AI-Powered Insights */}
                <Media 
                    title={'AI-Powered Insights'} 
                    icon={StarsInCircle} 
                    media={{
                        desktop: AIPoweredInsightsVideo,
                        poster: AIPoweredInsightsPoster
                    }}/>
                
                <BlackRounded/>
                <FAQ/>
                <DiveInto/>
            </main>
            
            <Footer/>
        </div>
    );
}

export default TeleAnalytixAI;