import React, { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import confirmedImg from "../../../assets/images/confirmed.png";
import { v4 as uuidv4 } from 'uuid';

import { MainContext } from '../../../App';
import axios from 'axios';
import { 
    signupApi, 
    // apiUrlDev, 
    // apiUrlQA,  
} from '../../../config';
import storage from '../../../config/firebase';

import Loader from '../../../components/Loader';

const Confirm = ({ NextButton, PrevButton, form, setForm }) => {
    const navigate = useNavigate();
    const { setModal } = useContext(MainContext);
    const [disabled, setDisabled] = useState(false);

    const uploadFiles = async () => {
        let newForm = {};

        for (let item in form) {
            if (typeof form[item] == 'object') {
                const newFileName = `${uuidv4()}-${form[item].name}`;
                
                await storage.ref(`/images/${newFileName}`).put(form[item])            
                // Getting Download Link
                const url = await storage.ref("images").child(newFileName).getDownloadURL();
                
                if (url && typeof url !== 'object') {
                    newForm[item] = String(url);
                }
            }
            else {
                newForm[item] = form[item];
            }
        }

        return newForm;
    }

    const sendForm = async () => {
        try {
            setDisabled(true);

            const newForm = await uploadFiles();            
            
            // let {data} = await axios.post(`${apiUrlQA}/mail`, newForm);
            
            await axios.get(signupApi, {
                params: newForm
            })
                .then((res) => {
                    if ( res.data.status == 'success') {
                        navigate('/');
                    }
                    else {
                        setModal({
                            type: 'error',
                            text: 'Something went wrong. Your form was not submitted.'
                        });
                    }
            });

            setDisabled(false);
        }
        catch(e) {
            setDisabled(false);
            console.log(e);
        }
    }

    return (
        <div className="app-signup-confirm">
            <h3>Confirmed</h3>

            {!disabled ?
                <>
                    <div className="app-signup-confirm-wrap">
                        <p>Welcome to TelecomsXChange!</p>

                        <div className="app-signup-confirm-img">
                            <img src={confirmedImg} alt="" />
                        </div>
                    </div>

                    <div className="app-signup-wrap-buttons">
                        <PrevButton text="Back"/>
                        <NextButton onClick={sendForm} disabled={disabled} text="Get Started"/>
                    </div>
                </>
                : <Loader />
            }
        </div>
    );
}

export default Confirm;