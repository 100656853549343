import React, { useEffect, useState } from 'react';
import Slider from "react-slick";

import {recentPosts} from "../../config";
import axios from 'axios';

import Loader from "../Loader";

// images
import Logo from '../../assets/images/home-slider/logo.svg';
import Avatar from '../../assets/images/home-slider/avatar.png';
import Avatar2 from '../../assets/images/home-slider/avatar2.png';
import Avatar3 from '../../assets/images/home-slider/avatar3.png';
import Citate from '../../assets/icons/citate-Icon.svg';

const HomeSlider = () => {

    const sliderSettings = {
        variableWidth: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        // swipe: true,
        // swipeToSlide: true,
        // autoplay: true,
        autoplaySpeed: 3000,
        speed: 500,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1028,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
                // settings: 'unslick'
            }
        ]
    }

    const [slides, setSlides] = useState(null);

    const getRecentPosts = async () => {
        try {
            const {data} = await axios.get(recentPosts);
    
            if (data) {
                setSlides(data);
            }
        }
        catch(e) {
            console.log(e);
        }
    }
    
    useEffect(() => {
        getRecentPosts();
    }, []);

    return (
        <section className="app-home-slider">
            <div className="container">
                <h2>Recent posts from our blog</h2>

                {
                    (slides && slides.length > 0) ? 

                        <Slider {...sliderSettings} className="app-home-slider-wrap">
                            {
                                slides.map((item, index) => {
                                    return (
                                        item.yoast_head_json ?
                                            <div className={`app-home-slider-item`} key={index}>
                                                <a href={item.link} target="_blank">
                                                    <div className="app-home-slider-item-head">
                                                        {/* {(item.jetpack_featured_media_url && item.jetpack_featured_media_url.length) ? 
                                                            <div className="app-home-slider-item-logo">
                                                                <img src={item.jetpack_featured_media_url} alt="" /> 
                                                            </div>
                                                        : null
                                                        } */}
                                                        {item.title?.rendered.length ? <h3>{item.title.rendered}</h3> : null}
                                                    </div>
                                                </a>

                                                <div className="app-home-slider-item-text">
                                                    <img src={Citate} alt="" />
                                                    {item.yoast_head_json.description ? <p>{item.yoast_head_json.description}</p> : null}
                                                </div>

                                                <div className="app-home-slider-item-person">
                                                    {/* {(item.yoast_head_json.schema["@graph"]) ? 
                                                        <div className="app-home-slider-item-person-avatar">
                                                            <img src={item.yoast_head_json.schema['@graph'].find(el => el["@type"] == 'Person').image.url} alt="" />
                                                        </div>
                                                    : null
                                                    } */}
                                                    
                                                    <div className="app-home-slider-item-person-text">
                                                        {/* {item.yoast_head_json.author ? <h6>{item.yoast_head_json.author}</h6> : null} */}
                                                        {
                                                            item?.modified ?
                                                                // <span>{new Date(item?.yoast_head_json?.article_published_time).toLocaleString('en-us', { month: 'short' })} {new Date(item.yoast_head_json.article_published_time).getDay()}, {new Date(item?.yoast_head_json?.article_published_time).getFullYear()}</span> 
                                                                <span>{new Date(item?.modified).toLocaleString('en-us', { month: 'short' })} {new Date(item.modified).getDay()}, {new Date(item?.modified).getFullYear()}</span> 
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        : null
                                    )                            
                                })
                            }
                        </Slider>
                    : 
                    <div className="app-home-slider-loader">
                        <Loader/>
                    </div>
                }
            </div>
        </section> 
    )
}

export default HomeSlider; 