import React from "react";
import Button from "../../../components/Button";

import { 
    BckStarsDark, 
    TeleAnalytixAILogo, 
    DownloadIconWhite
} from "../../../helpers/images";

const Hero = () => {
    return (
        <section className="app-section main-hero">
            <div className="main-hero-bcg">
                <img src={BckStarsDark} alt="" />
            </div>
            
            <div className="container">
                <div className="main-hero-wrap">
                    <div className="main-hero-content">
                        <h1>Empowering Telecom Insights
                            with <img src={TeleAnalytixAILogo} alt="" />
                        </h1>
                        <p>Revolutionary cutting-edge project powered by AI</p>
                        
                        <Button 
                            type='link'
                            link='https://drive.google.com/file/d/16n2VlbxEXcZ0SoCJHCOZmaclbOfg9Kry/view?usp=sharing' 
                            className={'purple-gradient'}
                            text={'White-Paper'}
                            icon={DownloadIconWhite} />                 
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;