import React from 'react';

const List = ({children}) => {
    return (
        <ul className="app-list">
            {
                children.map((item, index) => (
                    <li className="app-list-item" key={index}>
                        <div className={`app-list-item-img`} style={{backgroundColor: item.img.color}}>
                            <img src={item.img.icon} alt="" />
                        </div>
                        
                        <div className="app-list-item-text">
                            <h4>{item.title}</h4>
                            <p>{item.text}</p>
                        </div>
                    </li>
                ))
            }
        </ul>
    )
}

export default List;