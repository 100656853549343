import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";

// images
import CodeImg from "../../assets/images/platform-hero.png";
import CodeImgSecond from "../../assets/images/code-editor.png";
import arrRight from "../../assets/icons/arrow-right.svg";
import dragCircle from "../../assets/icons/drag-circle.svg";

import company_one from "../../assets/images/companies/1.png";
import company_two from "../../assets/images/companies/2.png";
import company_three from "../../assets/images/companies/3.png";
import company_four from "../../assets/images/companies/4.png";
import company_five from "../../assets/images/companies/5.png";

import cloudImg from "../../assets/images/cloud-home.png";
import platformImgOne from "../../assets/images/platform-1.png";
import platformImgSecond from "../../assets/images/platform-2.png";

import categoryImg from "../../assets/icons/category.svg";
import searchImg from "../../assets/icons/search.svg";
import walletImg from "../../assets/icons/wallet.svg";

// components
import Tag from "../../components/Tag";
import Button from "../../components/Button";
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import List from "../../components/List";
import HomeSlider from "../../components/HomeSlider";
import Newsletter from "../../sections/Newsletter";
import WhitePapper from '../../sections/WhitePapper';

// sections

import Integrations from '../../sections/Integrations';

const Home = () => {

    const companies = [company_one, company_two, company_three, company_four, company_five]

    const [isAnimattedDrag, setIsAnimatedDrag] = useState(true);
    const [dragValue, setDragValue] = useState(500);
    
    const dragChange = (e) => {
        setDragValue((e.target.value));
        setIsAnimatedDrag(false);
    }

    useEffect(() => {
        if (isAnimattedDrag == false) {
            let animatedTimer = setTimeout(() => {
              setIsAnimatedDrag(true);
            }, 700);

            return () => clearTimeout(animatedTimer);
        }
    }, [dragValue])

    return (
        <div className="app-home">
            <Header/>
        
            <div className="main-hero">
                <div className="container">
                    <div className='main-hero-wrap circle'>
                        <div className="main-hero-content">
                        <h1>Building the future of <span>Autonomous </span>telecom <span>wholesale</span> platform</h1>

                            
                            <p>Accelerating CSPs' digital transformation with <span>Autonomous</span>, Intelligent, and OPEN wholesale platform.</p>
                            
                            <Link to="/cloud" className='main-hero-content-link'>
                                <div className="main-hero-content-link-arrow">
                                    <img src={arrRight} alt="" />
                                </div>

                                Get Started
                            </Link>
                        </div>

                        <div className="main-hero-img circle">
                            <div className="app-home-hero-img-wrap">
                                <img className="app-home-hero-img" src={CodeImg} alt="" />
                            </div>
                            <div className="app-home-hero-img-wrap second" style={{'width': (100 - (dragValue / 10)) + '%'}}>
                                <img className="app-home-hero-img" src={CodeImgSecond} alt="" />
                            </div>
                            <input 
                                type="range" 
                                min="1" 
                                max="1000" 
                                value={dragValue} 
                                onChange={dragChange}
                                className="app-home-hero-drag" 
                                name='app-home-hero-drag' id="app-home-hero-drag"></input>
                            
                            <div 
                                style={{'left': (dragValue / 10) + '%'}}
                                className={`app-home-hero-drag-line`}>

                                <span className={isAnimattedDrag ? 'animated': ''}>
                                    <img src={dragCircle} alt="" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="app-home-hero-companies">
                        <h3>Featured Service Providers </h3>
                        <div className="app-home-hero-companies-wrap">
                            {companies.map((item, index) => (
                                <div className="app-home-hero-companies-item" key={index}><img src={item} alt="" /></div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

           <section className="app-home-cloud">
                <div className="container">
                    <div className="app-home-description">
                        <Tag text="TCXC XaaS"/>

                        <h2>TCXC - <span>Marketplace</span> Enablement</h2>
                        <p>Autonomous marketplace for CSPs to transform communication with cutting-edge billing and exchange capabilities.</p>
                        <Button type={'link'} link='/cloud'/>
                    </div>
                </div>

                <div className="container">
                    <div className="app-home-cloud-img">
                        <img src={cloudImg} alt="" />
                    </div>

                    <List children={[
                        {
                            img: {
                                icon: categoryImg,
                                color: '#542FBE'
                            },
                            title: 'Enhanced Dashboard',
                            text: 'Buyers and sellers are equipped with trading tools that blend ease of use with technical sophistication.'
                        },
                        {
                            img: {
                                icon: searchImg,
                                color: '#262D3A'
                            },
                            title: 'Market View Search',
                            text: 'Explore the telecom landscape with our Market View search feature, offering real-time insights and comparisons across a wide range of services including voice, SMS, HLR and virtual numbers. Make informed decisions effortlessly, finding the best deals and providers to meet your specific needs within the Marketplace platform.'
                        },
                        {
                            img: {
                                icon: walletImg,
                                color: '#DBFA60'
                            },
                            title: 'FastPay Fund Transfers ',
                            text: 'We have focued on providing marketplace buyers and sellers with fastest and most secure way to transfer funds. Our FastPay Fund Transfer service is available to all marketplace buyers and sellers. With our service, buyers can transfer funds to their accounts within minutes and sellers can transfer funds to their accounts within hours.'
                        }
                    ]} />
                </div>
            </section>           

            <section className="app-home-platform">
                <div className="container">
                    <div className="app-home-description">
                        <Tag text="TCXC PaaS"/>

                        <h2>TCXC <span>Platform</span> as a Service (PaaS)</h2>
                        <p>Autonomous wholesale platform for telecom operators to seamlessly interconnect with partners, fully automate wholesale operations and go to market quickly.
                        </p>
                        <Button link="/platform"/>
                    </div>
                </div>
                <div className="container">
                    <div className="app-home-platform-items">
                        <div className="app-home-platform-item">
                            <div className="app-home-platform-item-text">
                                <h4>Reimagine your product with forward-thinking solutions</h4>
                                <p>Elevate your business to new heights by fully automating and digitizing the wholesale exchange of voice, SMS, and phone numbers. Embrace innovation by joining our Digital Exchange platform, designed to streamline your transactions and operations, ensuring seamless buying and selling experiences for your partners.</p>
                            </div>
                            <div className="app-home-platform-item-img">
                                <img src={platformImgOne} alt="" />
                            </div>
                        </div>
                        
                        <div className="app-home-platform-item">
                            <div className="app-home-platform-item-text">
                                <h4>Empowering with Openness and Transparency</h4>
                                <p>At TelecomsXChange (TCXC), our Platform-as-a-Service goes beyond digital transformation. We offer unparalleled source code access and full transparency into our TCXC PaaS stack, empowering global carriers to not only automate and digitize their VoIP, SMS, and number services but also to fully understand and customize their underlying technologies. This open approach makes TCXC the strategic telecom stack partner for wholesale businesses seeking to innovate, scale, and lead in their markets.</p>
                            </div>
                            <div className="app-home-platform-item-img mt-40">
                                <img src={platformImgSecond} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>   
            
            
            <HomeSlider/>
            
            <Newsletter/>
    
            <Footer/>
        </div>
    )
}

export default Home;