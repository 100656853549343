import React, { useId, useState } from "react";
import Title from "../../../components/Title";
import { FaqInCircle } from "../../../helpers/images";

const FAQ = () => {
    let data = [
        {
            question: 'How does TeleAnalytixAI work?',
            answer: 'TeleAnalytixAI functions as an advanced chatbot that interacts directly with your telecom network via the TelecomsXChange API. Upon receiving a query, it fetches the relevant data and passes it to your AI provider of choice—such as OpenAI, xAI, or Bard—for further analysis, summarization, or prediction. The AI-generated insights are then returned to you through the chatbot interface, enabling real-time, data-driven decision-making.'
        },
        
        {
            question: 'Who are the AI providers behind TeleAnalytixAI?',
            answer: 'TeleAnalytixAI works with leading AI providers such as OpenAI, xAI, and Bard to deliver state-of-the-art Ai driven analytics and insights.'
        },
        {
            question: 'What benefits does TelecomsXChange API Integration offer?',
            answer: 'Integration with TelecomsXChange API enables seamless telecom data transfer and real-time analytics, enhancing the operational efficiency and data-driven decision-making of telecom operators.'
        },
        {
            question: 'What is the pricing model for TeleAnalytixAI?',
            answer: 'TeleAnalytixAI offers a tiered pricing model based on the volume of data processed and the number of analytics modules utilized. Custom plans are also available.'
        },
        {
            question: 'What is the minimum monthly spend in the pricing model?',
            answer: 'The minimum monthly spend starts at $500, which includes a set number of analytics modules and data processing capabilities.'
        },
        {
            question: 'How can TeleAnalytixAI benefit telecom operators?',
            answer: 'TeleAnalytixAI provides telecom operators with real-time analytics, predictive maintenance capabilities, fraud detection, and customer insights, thereby improving operational efficiency and customer satisfaction.'
        },
        {
            question: 'What types of telecom data sources can I analyze with TeleAnalytixAI?',
            answer: 'You can analyze a wide range of telecom data sources, including call, messaging logs, network performance metrics, customer usage data, and billing records.'
        },
        {
            question: 'Can TeleAnalytixAI handle large volumes of telecom data?',
            answer: 'Yes, TeleAnalytixAI is designed to scale and handle large volumes of data efficiently, thanks to its robust architecture and advanced data processing algorithms.'
        },
        {
            question: 'Can I customize TeleAnalytixAI to suit my specific telecom data analysis needs?',
            answer: 'Absolutely, TeleAnalytixAI offers the option to build or import custom AI models tailored to your specific analytics requirements.'
        },
        {
            question: 'How does TeleAnalytixAI ensure data security and privacy?',
            answer: 'TeleAnalytixAI employs stringent security protocols, including encryption and multi-factor authentication, to ensure data security and compliance with privacy regulations.'
        },
        {
            question: 'What is the current development stage of TeleAnalytixAI?',
            answer: 'TeleAnalytixAI is currently in its Alpha version. We are actively seeking feedback and making continual improvements to offer a more robust and feature-rich product in the upcoming releases.'
        },
        {
            question: 'How can I get started with TeleAnalytixAI and begin using its features?',
            answer: 'To start using TeleAnalytixAI, you must have TCXC PaaS already installed in your telecom network. Once that is in place, you can sign up for a free demo or contact our sales team for setup. After your account is configured, you can access TeleAnalytixAI through a secure login and begin leveraging its features immediately.'
        }
    ]
    

    const id = useId();
    const [ currentlyOpened, setCurrentlyOpened ] = useState(0);

    return (
        <section className="app-section faq">
            <div className="container">
                <Title icon={FaqInCircle}>Frequently Asked Questions</Title>
            
                <div className="faq__items">
                {
                    data.map((item, index) => (
                        <div 
                            className={`faq__item ${currentlyOpened === index ? 'faq__item_collapsed' : ''}`} 
                            key={`${id}-${index}`}
                            onClick={() => setCurrentlyOpened((prev) => prev !== index ? index : false )}>

                            <div className="faq__item-question">
                                <h4>{item.question}</h4>
                            </div>
                            <div className="faq__item-answer">
                                <p>{item.answer}</p>
                            </div>
                        </div>
                    ))
                }
                </div>
            </div>
        </section>
    );
}

export default FAQ;