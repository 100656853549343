import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../assets/images/logo-light.svg";
import ytIcon from "../../assets/icons/youtube.svg";
import twIcon from "../../assets/icons/twitter.svg";
import ghIcon from "../../assets/icons/github.svg";
import linkedIcon from "../../assets/icons/linkedin.svg";

import File from "../../assets/files/white-papper.pdf";

const Footer = () => {

    const footerMenu = [
        {
            title: 'Marketplace Enablement',
            items: [
                {
                    title:'Voice Exchange',
                    link: '/cloud',
                    type: 'link'
                }, 
                {
                    title:'Messaging Exchange',
                    link: '/cloud',
                    type: 'link'
                }, 
        
                {
                    title:'Numbers Exchange',
                    link: '/cloud',
                    type: 'link'
                }, 
                {
                    title:'HLR Exchange',
                    link: '/cloud',
                    type: 'link'
                }, 
             
             
         /*        {
                    title: 'WABA Integration',
                    link: 'https://telecomsxchange.formstack.com/forms/whatsapp_notifications',
                    
                    type: 'href'
                } */

               
            ]
        },
        {
            title: 'TCXC Platform as a Service (PaaS)',
            items: [
                {
                    title:'Download White Paper',
                    link: File,
                    type: 'href',
                    target: "_blank"
                }, 
                {
                    title: 'Book Demo',
                    link: '/contact',
                    type: 'link'
                },
                {
                    title: 'Case Study',
                    link: '/platform',
                    type: 'link'
                },
            
         
                {
                    title: 'IoT AAA',
                    link: '/iot',
                    type: 'link',
                },
           
                {
                    title:'Ai Integration',
                    link: '/teleanalytixai',
                    type: 'link'
                }, 

            ]
        },
        {
            title: 'Resources',
            items: [
                {
                    title:'TCXC Members Subscription',
                    link: '/contact',
                    type: 'link'
                }, 
                {
                    title:'Helpdesk',
                    link: 'https://telecomsxchange.freshdesk.com',
                    type: 'href'
                }, 
                {
                    title: 'TextShield.Ai',
                    link: 'https://textshield.ai',
                    type: 'href'
                },
                
                {
                    title:'API Docs',
                    link: 'https://apidocs.telecomsxchange.com',
                    type: 'href'
                }, 


                {
                    title:'Number Lookup',
                    link: 'https://tools.telecomsxchange.com',
                    type: 'href'
                
                },
             
                

                {
                    title:'Privacy',
                    link: '/privacy',
                    type: 'link'
                },
                {
                    title:'Terms',
                    link: '/terms',
                    type: 'link'
                },
            ]
        },
        {
            title: 'About us',
            items: [
                {
                    title:'Linkedin',
                    link: 'https://www.linkedin.com/company/telecomsxchange/',
                    type: 'href'
                }, 
                {
                    title: 'Blog',
                    link: 'https://blog.telecomsxchange.com',
                    type: 'href'
                }, 
                {
                    title: 'Github',
                    link: 'https://github.com/telecomsxchangeapi/',
                    type: 'href'
                }, 
                {
                    title: 'Telecom GPT',
                    link: 'https://chat.openai.com/g/g-eSwyzwwDV-telecom-gpt',
                    type: 'href'
                },

                /* Disable this option for now
                {
                    title: 'Jobs',
                    link: '/#',
                    type: 'link'
                }, 
                {
                    title: 'Press',
                    link: '/#',
                    type: 'link'
                }, 
                {
                    title: 'Partners',
                    link: '#',
                    type: 'link'
                }*/
            ]
        },
        {
            title: 'Contact Us',
            items: [
                {
                    title: '1800 N Bayshore DR',
                    link: '/#',
                    type: 'text'
                }, 
                {
                    title: 'Suite CU1',
                    link: '/#',
                    type: 'text'
                }, 
                {
                    title:'Miami, FL 33132',
                    link: '/#',
                    type: 'text'
                }
            ]
        }
    ];
    
    return (
        <footer className="app-footer">
            <div className="container">
                <div className="app-footer-nav">
                    <div className="app-footer-logo">
                        <img src={Logo} alt="" />
                    </div>

                    <ul className="app-footer-social">
                
                       
                        <li>
                            
                            <a target="_blank" href='https://twitter.com/telecomsxchange'>
                                <img src={twIcon} alt="TCXC Twitter Handler" />
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href='https://github.com/telecomsxchangeAPI'>
                                <img src={ghIcon} alt="TCXC Github Profile" />
                            </a>
                        </li>
                        <li>
                           
                            <a target="_blank" href='https://www.youtube.com/telecomsxchangetrading'>
                                <img src={ytIcon} alt="TCXC YouTube Channel" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="container">
                <div className="app-footer-menu">
                {
                    footerMenu.map((item, index) => (
                        <ul key={item.title}>
                            <li><span>{item.title}</span></li>
                            {
                                item.items.map(elem => (
                                    <li key={elem.title}>
                                        {
                                            elem.type == 'text' ?
                                                <p>{elem.title}</p> :
                                            elem.type == 'link' ?
                                                <Link to={elem.link}>{elem.title}</Link> :
                                            elem.type == 'href' ?
                                                <a target={elem.target ?? '_self'} href={elem.link}>{elem.title}</a> :
                                            null
                                        }
                                    </li>
                                ))
                            }
                        </ul>
                    ))
                }
                </div>
            </div>
        </footer>
    );
}

export default Footer;