const mediaType = (media) => {
    let ext = media.split('.').pop();

    switch(ext) {
        case 'svg':
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'webp':
        case 'ico':
            return 'image';
        case 'avi':
        case 'mp4':
        case 'mpeg':
            return 'video';
        case 'mp3':
        case 'flac':
        case 'ogg':
        case 'wav':
            return 'audio';
    }
}

export default mediaType;