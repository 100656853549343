import React, { useId } from "react";
import Title from "../../../components/Title";
import { 
    ChartInCircle,
    TeleAnalytixAILogoWith,
    AnalysingInCircleBig,
    LampInCircleBig,
    FutureInCircleBig,
    TeleAnalytixChatIcon 
} from "../../../helpers/images";

const BlackRounded = () => {
    const id = useId();
    let items = [
        {
            img: AnalysingInCircleBig,
            content: <h3>Analysing <b>The Past</b></h3>,
            size: 'normal'
        },
        {
            img: LampInCircleBig,
            content: <h3>Understanding <b>The Present</b></h3>,
            size: 'normal'
        },
        {
            img: FutureInCircleBig,
            content: <h3>Predicting <b>The Future</b></h3>,
            size: 'big'
        },
    ];

    return (
        <section className="app-section black-rounded">
            <span className="black-rounded__circle black-rounded__circle_top"></span>

            <div className="black-rounded__wrap">
                <div className="black-rounded__content container">
                    <Title icon={ChartInCircle} color="white">Predictive Analytics</Title>

                    <div className="black-rounded__logo">
                        <img src={TeleAnalytixAILogoWith} alt="" />
                    </div>

                    <div className="black-rounded__steps">
                        {
                            items.map((item, index) => (
                                <div className={`black-rounded__step black-rounded__step_${item.size}`} key={`${id}-${index}`}>
                                    <div className="black-rounded__step-img">
                                        <img src={item.img} alt="" />
                                    </div>
                                    <div className="black-rounded__step-content">{item.content}</div>
                                </div>
                            ))
                        }
                    </div>

                    <div className="black-rounded__info">
                        <p><strong>Tele<b>Analytix:</b></strong> Shaping Future Strategies. </p>
                        <p>Predicting trends with advanced machine learning for your proactive decisions.</p>
                    </div>

                    <div className="black-rounded__messages">
                        <div className="black-rounded__messages-wrap">
                            <div className="black-rounded__messages-item black-rounded__messages-item_outgoing">                                
                                <div className="black-rounded__messages-item-text">
                                    <p>Analyse upcoming data trends.</p>
                                </div>
                            </div>

                            <div className="black-rounded__messages-item black-rounded__messages-item_incoming">
                                <span className="black-rounded__messages-item-icon">
                                    <img src={TeleAnalytixChatIcon} alt="" />
                                </span>

                                <div className="black-rounded__messages-item-text">
                                    <p><b>Expect a 20% increase in weekend data usage during afternoons,</b> based on historical data and current patterns. Consider offering targeted data packages and promotions to maximise revenue.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <span className="black-rounded__circle black-rounded__circle_bottom"></span>
        </section>
    );
}

export default BlackRounded;