import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import Logo from "../../assets/images/logo-light.svg";
import LogoDark from "../../assets/images/logo-dark.svg";
import CloudIcon from "../../assets/icons/cloud-icon.svg";
import LayerIcon from "../../assets/icons/layers-icon.svg";
import chDown from "../../assets/icons/chevron-down.svg";
import arrRight from "../../assets/icons/arrow-right.svg";
import IotIcon from "../../assets/icons/connection-hosting-icon.svg";
import AiIcon from "../../assets/icons/teleanalytix/chat-icon.svg";
import MfaIcon from "../../assets/icons/security-icon.svg";

import { MainContext } from '../../App';

const Header = ({ isFilled }) => {
    const [menuCollapsed, setMenuCollapsed] = useState(false); 
    const [filled, setFilled] = useState(isFilled ?? false);
    const [togglerActive, setTogglerActive] = useState(false);
    
    const {isMobile, currentWidth} = useContext(MainContext);

    const menuItems = [
        {
            text: 'Solutions',
            link: "#",
            type: 'link',
            dropdown: [
                {
                    icon: CloudIcon,
                    title: 'TCXC Marketplace',
                    text: 'Autonomous marketplace for CSPs to transform communication with cutting-edge billing and exchange capabilities.',
                    link: '/cloud',
                    type: 'link',
                },
                {
                    icon: LayerIcon,
                    title: 'TCXC PaaS',
                    text: 'Autonomous platform for telecom operators to seamlessly interconnect with partners, fully automate wholesale operations.',
                    link: '/platform',
                    type: 'link',
                },
                {
                    icon: MfaIcon,
                    title: 'Open Text Shield (OTS)',
                    text: "Real-time Messaging Security Powered by TCXC's open source pre-trained models, prevent smishing and fraud before it happens.",
                    link: 'https://github.com/TelecomsXChangeAPi/OpenTextShield',
                    type: 'a',
                },
                {
                    icon: IotIcon,
                    title: 'TCXC IoT AAA',
                    text: 'MqTT with AAA for Internet of Things',
                    link: '/iot',
                    type: 'link',
                },
                {
                    icon: AiIcon,
                    title: 'TeleAnalytiX',
                    text: 'Cutting-edge project that harnesses the power of generative AI to revolutionize the way telecom operators analyze and interpret their data.',
                    link: '/teleanalytixai',
                    type: 'link',
                },
            
            ] 
        },
        {
            text: 'API docs',
            link: 'https://apidocs.telecomsxchange.com', 
            type: 'a'
        }, 
 

        {
            text: 'Blog',
            link: 'https://blog.telecomsxchange.com/', 
            type: 'a',
            tooltip: 'TCXC blog',
        },
        {
            text: 'Contact Us',
            link: '/contact', 
            type: 'link',
        },
    ];

    useEffect(() => {
        if (!isMobile) {
            setTogglerActive(false)
        }
    }, [currentWidth]);

    useEffect(() => {
        if (window.scrollY >= 40) {
            setFilled(true);
        }

        window.addEventListener('scroll', (e) => {
            if (!isFilled) {
                if (e.target.defaultView.scrollY >= 40) {
                    setFilled(true);
                }
                else {
                    setFilled(false);
                }
            }
        });

    },[])

    return (
        <div 
            className={`app-header ${filled ? 'filled' : ''} ${isMobile ? (togglerActive ? 'collapsed': ''): ''}`}
            style={{ '--header-height': '50px' }} >
            <div className="container">
                <Link className="app-header-logo" to="/">
                    <div className="app-header-logo-light">
                        <img src={Logo} alt="" />
                    </div>
                    <div className="app-header-logo-dark">
                        <img src={LogoDark} alt="" />
                    </div>
                </Link>

                <div className={`app-header-toggler ${togglerActive ? 'active': ''}`} onClick={() => {
                    if (!togglerActive) {
                        setMenuCollapsed(menuItems[0].dropdown);
                    } 
                    else {
                        setMenuCollapsed(false)
                    }
                    setTogglerActive(!togglerActive);
                }}>
                    <span className="app-header-toggler-line one"></span>
                    <span className="app-header-toggler-line two"></span>
                    <span className="app-header-toggler-line three"></span>
                </div>

                <div className={`app-header-nav ${!togglerActive ? 'hidden' : ''}`}>

                    <div className={`app-header-menu-collapsed ${!togglerActive ? (!menuCollapsed ? 'hidden' : ''): ''}`} 
                        onMouseLeave={() => window.innerWidth > 992 && setMenuCollapsed(false)}
                    >
                        <div className="container">
                            <div className={`app-header-menu-collapsed-wrap ${!isMobile ? 'custom-scroll' : ''}`}>
                                {
                                    menuCollapsed && menuItems[0].dropdown.map(item => (
                                        <div className="app-header-menu-collapsed-item" key={item.title}>
                                            <Link to={item.link} onClick={() => isMobile && togglerActive ? setTogglerActive(false) : false}>
                                                <div className="app-header-menu-collapsed-item-content">
                                                    <div className="app-header-menu-collapsed-item-icon">
                                                        <img src={item.icon} alt="" />
                                                    </div>
                                                    <h5>{item.title}</h5>
                                                    <p>{item.text}</p>
                                                </div>
                                            </Link>

                                            <Link className="app-header-menu-collapsed-item-link" to={item.link}>Learn more <img src={arrRight} alt="" /></Link>
                                        </div>
                                    ))
                                }
                                </div>
                            </div>
                        </div>

                    <ul className={`app-header-menu`}>
                        {
                            menuItems.map(item => (
                                <li key={item.text} 
                                    onMouseEnter={() => window.innerWidth > 992 && item.dropdown && setMenuCollapsed(item.dropdown)}
                                    onMouseLeave={e => {
                                        if (!isMobile) {
                                            if (item.dropdown && (e.relatedTarget.className.includes('app-header-menu-collapsed'))) {
                                                setMenuCollapsed(item.dropdown);
                                            }
                                            else {
                                                setMenuCollapsed(false)
                                            }
                                        }
                                    }}>
                                    
                                    {
                                        item.type == 'link' ?
                                        <Link to={item.link}>
                                            <span>
                                                {item.text}
                                                {item.dropdown && <img src={chDown} className={`app-header-menu-dropdown ${menuCollapsed ? 'active': ''}`}/> }
                                            </span>
                                        </Link>
                                        :
                                        <a href={item.link}>
                                            <span>
                                                {item.text}
                                                {item.dropdown && <img src={chDown} className={`app-header-menu-dropdown ${menuCollapsed ? 'active': ''}`}/> }
                                            </span>
                                        </a>
                                    }
                                </li>                             
                            ))
                        }
                    </ul>

                    <div className="app-header-btns">
                        <Link to="/contact" className='app-button green'>Learn More</Link>
                        <a href="https://members.telecomsxchange.com" className={`app-button ${isMobile ? 'dark' : 'light'}`}>Sign On</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;