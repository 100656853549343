import React, { useContext, useEffect, useRef} from 'react';
import { MainContext } from '../../App';

// images
import MainImage from "../../assets/images/cases-img.png";
import bcgOne from "../../assets/images/cases-bcg-1.png";
import bcgTwo from "../../assets/images/cases-bcg-2.svg";

const Cases = () => {
    const {currentWidth} = useContext(MainContext);
    const tagsItems = useRef([]);

    function detectSecondRow(){
        if (tagsItems?.current && currentWidth > 992) {

            let flexChildren = tagsItems.current;
            let leftPosition = flexChildren[0].offsetLeft;
            let offsetRowIndex = 0;

            flexChildren.forEach((flexChild, index) => {
                flexChild.style.marginLeft = '0px';

                if(flexChild.offsetLeft <= leftPosition && index !== 0){
                    offsetRowIndex++;
                    
                    if (offsetRowIndex % 2 !== 0) {
                        flexChild.style.marginLeft = '120px';
                    }
                }
                else {
                    flexChild.style.marginLeft = '0px';
                }
                
            });
        }
      }

    useEffect(() => {
        detectSecondRow();
    }, [currentWidth]);

    const caseTags = [
        'Wholesale Business Digital Tranformation', 
        'Build Developers Community / Increase innovation ',
        'Automate Rate processing and notification ',
        'Automate Prepaid payment processing for wholesale',
        'Platform-to-Platform integration with APIs',
        'Unlock Market Place Communities',
        'Cloud Integrations using APIs (Zoom, Slack, Zapier, ets)',
        'Digitize (Voice, SMS, Phone Numbers) for other CSPs',
        'Add Ai or Blockchain to your wholesale workflows',
        'CPaaS AAA (Billing)'
    ];

    return (
        <div className="app-cases">
            <div className="container">
                <h3>Use Cases</h3>

                <div className="app-cases-tags">
                    {
                        caseTags.map((item, index) => (
                            <div key={item} className={`app-cases-tags-item`} ref={el => tagsItems.current[index] = el}>
                                <span>{item}</span>
                            </div>
                        ))
                    }
                </div>

                <div className="app-cases-img">
                    <img src={MainImage} className="app-cases-img-main" alt="" />
                </div>

                <img src={bcgOne} className="app-cases-img-bcg one" alt="" />
                <img src={bcgTwo} className="app-cases-img-bcg two" alt="" />
            </div>

            <div className="app-cases-circle one"></div>
            <div className="app-cases-circle two"></div>
            <div className="app-cases-circle three"></div>
        </div>
    );
}

export default Cases;