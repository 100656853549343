import React, { useEffect, useState } from "react";

// sections
import Header from '../../sections/Header';
import Footer from '../../sections/Footer';
import Form from "../../sections/Form";

// images
import sidebarImg from "../../assets/images/contact-sidebar.png"
import mailImg from "../../assets/images/mail-green.svg"
import wavesImg from "../../assets/images/waves.svg"
import phoneImg from "../../assets/icons/phone-filled.svg"

// components
import SmallHero from "../../components/SmallHero";
import Button from '../../components/Button';

// steps
import Fill from "./Steps/Fill";
import VerifyPhone from "./Steps/VerifyPhone";
import VerifyCode from "./Steps/VerifyCode";
import Confirm from "./Steps/Confirm";

const SignUp = () => {

    const [step, setStep] = useState(0);
    const [verificationCode, setVerificationCode] = useState(null);

    let [fields, setFields] = useState({
        company_info: {
            company_name: {
                title: 'Company Name*',
                type: 'text',
                required: true,
                validate: false

            },
            company_site: {
                title: 'Company Website*',
                type: 'text',
                required: true,
                validate: false
            },
            company_logo: {
                title: 'Company LOGO (High Resolution )*',
                type: 'file',
                required: true,
                validate: false
            },
            company_certificate: {
                title: 'Upload Company Registration Certificate*',
                type: 'file',
                required: true,
                validate: false
            },
        },
        contact_info: {
            us_based: {
                title: 'Are you U.S Based ?',
                type: 'radio',
                fields: ['Yes', 'No']
            },
            first_name: {
                title: 'First Name*',
                type: 'text',
                required: true,
                validate: false
            },
            last_name: {
                title: 'Last Name*',
                type: 'text',
                required: true,
                validate: false
            },
            email: {
                title: 'E-mail*',
                type: 'email',
                required: true,
                validate: false
            },
            noc_email: {
                title: 'NOC Email*',
                type: 'email',
                required: true,
                validate: false
            },
            rates_email: {
                title: 'Rates E-mail*',
                type: 'email',
                required: true,
                validate: false
            },
            membership: {
                title: 'Membership Type*',
                type: 'radio',
                fields: ['Buyer', 'Seller', 'Buyer & Seller'],
                required: true,
                validate: false
            },
            interested: {
                title: 'Which services are you interested in Buying?*',
                type: 'dropdown',
                fields: ['Voice Exchange', 'SMS Exchange', 'DID Exchange', 'Other'],
                required: true,
                validate: false
            },
            equipment: {
                title: 'Switching Equipment*',
                type: 'dropdown',
                fields: ['Huawei SBC', 'Genbanbd', 'Asterisks', 'FreeSwitch', '3CX PBX', 'FreePBX', 'Sonus', 'Cloud Softswitch', 'Incredible PBX', 'Telarix', 'VoipSwitch', 'Phoenix Soft', 'DenvoLab', 'VOS', 'iTel', 'Pactolus', 'Nextone', 'PortaOne', 'SippySoft', 'IPSmarx', 'KolmoSoft', 'IXC Softswitch', 'JVS Softswitch', 'Dialogic', 'Cisco', 'Other', 'Digital'],
                required: true,
                validate: false
            },
            skype: {
                title: 'Skype ID*',
                type: 'text',
                required: true,
                validate: false
            },
            sip: {
                title: 'SIP Signaling IP-Address',
                type: 'text',
                required: true,
                validate: false
            },
            phone: {
                title: 'Phone',
                type: 'phone',
                country: 'us',
                placeholder: '+1 (555) 987-6543'
            },
            address: {
                title: 'Street Address*',
                type: 'text',
                required: true,
                validate: false
            },
            country: {
                title: 'Country Name*',
                type: 'text',
                required: true,
                validate: false
            },
            message: {
                title: 'Tell us more about your business case or project*',
                type: 'textarea',
                placeholder: 'Write your message',
                required: true,
                validate: false
            }
        }
    });

    let sidebar = [
        {
            title: 'fill fields',
            text: 'Vitae sed mi luctus laoreet.'
        },
        // {
        //     title: 'verify identity',
        //     text: 'Cursus semper viverra facilisis et et some more.'
        // },
        // {
        //     title: 'verify identity',
        //     text: 'Secure Identity verification'
        // },
        {
            title: 'Confirmation step',
            text: 'Penatibus eu quis ante.'
        },
    ];

    const [form, setForm] = useState({
        to: 'bdm1.perfectorium@gmail.com',
        subject: 'TelecomsXChange',
        
        company_name: '',
        company_site: '',
        company_logo: '',
        company_certificate: '',
        interested: 'Voice',
        equipment: 'Huawei SBC',
        us_based: 'No',
        first_name: '',
        last_name: '',
        email: '',
        noc_email: '',
        rates_email: '',
        membership: 'Buyer',
        equipment: 'Huawei SBC',
        phone: '',
        skype: '',
        sip: '',
        address: '',
        country: '',
        message: ''
    }); 

    const changeStep = (dir) => {
        if (dir == 'next' && step !== 3) {
            setStep(step + 1);
        }
        else if (dir == 'prev' && step !== 0) {
            setStep(step - 1);
        }
    } 

    const PrevButton = ({text}) => <Button onClick={() => changeStep('prev')} type="submit" className={`without-border ${step == 0 ? 'hidden' : ''} prev`} text={text}/>;
    const NextButton = ({text, disabled, link, type, onClick}) => <Button disabled={disabled} onClick={onClick} className={`next`} type={type ?? 'submit'} link={link ?? ''} text={text}/>;

    return (
        <div className="app-signup">
            <Header />
            <SmallHero children={
                <>
                    <div className="small-hero-text">
                        <h1>Sign Up</h1>
                     </div>

                    <Form sidebar={
                            <div className='app-signup-steps'>
                                <h3>Sign Up</h3>

                                <ul className="app-signup-steps-wrap">
                                    {
                                        sidebar.map((item, index) => (
                                            <li className={`app-signup-steps-item ${(index == step) ? 'active': ''} ${(step > index) ? 'filled': ''}`} key={item.text}>
                                                <div className="app-signup-steps-item-dot">
                                                </div>
                                                <div className="app-signup-steps-item-text">                
                                                    <span>{item.title}</span>
                                                    <p>{item.text}</p>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>

                                <div className="app-signup-steps-images">
                                    <img src={sidebarImg} className="first" alt="" />    
                                    <img src={mailImg} className="second" alt="" />    
                                    <img src={wavesImg} className="third" alt="" />    
                                    <img src={phoneImg} className="fourth" alt="" />    
                                </div>                                
                            </div>
                        }

                        content={
                            <div className="app-signup-wrap">
                                {
                                    step == 0 ?
                                    <Fill {...{fields, setFields, form, setForm, changeStep, step, NextButton}}/>
                                    // : step == 1 ?
                                    // <VerifyPhone {...{form, setForm, fields, changeStep, step, PrevButton, NextButton, setVerificationCode}}/>
                                    // : step == 2 ? 
                                    // <VerifyCode {...{form, setForm, fields, changeStep, step, PrevButton, NextButton, verificationCode, setVerificationCode}}/> 
                                    : step == 1 ?
                                    <Confirm {...{form, setForm, fields, changeStep, step, PrevButton, NextButton}}/> 
                                    : null
                                }
                            </div>
                        }
                    />
                </>
            } className="circles"/>
            <Footer />
        </div>
    )
}

export default SignUp;

