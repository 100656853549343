import React from "react";

const Form = ({ sidebar, content }) => {
    return (
        <div className="app-form">
            <div className="app-form-sidebar">
                {sidebar}
            </div>
        
            <div className="app-form-main">
                {content}
            </div>
        </div>
    );
}

export default Form;