import React, { useContext } from 'react';

import { MainContext } from '../../App';
import Button from '../Button';

import checkIcon from "../../assets/icons/check.svg";
import closeIcon from "../../assets/icons/close.svg";
import errorIcon from "../../assets/icons/error.svg";

const Modal = ({ type, text, className }) => {
    const { setModal } = useContext(MainContext);

    return (
        <div className={`app-modal ${className}`}>
            <div className="app-modal-bcg" onClick={() => setModal(false)}></div>

            <div className="app-modal-wrap">
                <img onClick={() => setModal(false)} src={closeIcon} className="app-modal-wrap-close" alt="" />

                <div className="app-modal-wrap-img">
                    <img src={type == 'success' ? checkIcon : type == 'error' ? errorIcon : null} alt="" />
                </div>
                
                <h3>{type == 'success' ? 'Success' : ''}</h3>
                <p>{text}</p>

                <Button type="submit" className={'green without-arrow'} text="ok" onClick={() => setModal(false)}/>
            </div>
        </div>
    )
}

export default Modal